<template>
  <div class="overflow-auto fixed top-0 left-0 z-20 w-full h-screen bg-white">
    <top-header class-names="bg-sky-500 text-black">
      <template #left>Focus mode</template>

      <template #right>
        <icon-button
          :icon="XMarkIcon"
          :to="{ name: 'session', params: { sessionId: goal.session.id } }"
          position="right"
          title="Sluiten"
        />
      </template>
    </top-header>

    <div class="md:grid md:grid-cols-3">
      <div
        class="p-4 bg-gray-100 md:overflow-auto md:col-span-2 md:p-8 md:pt-28 md:h-screen md:min-h-screen"
      >
        <h2 class="mb-4 text-2xl">{{ goal.competency.title }}</h2>

        <div
          class="mb-4"
          v-html="
            locale === 'nl' ? goal.competency.body : goal.competency.bodyEn
          "
        />

        <session-goal-card
          :current="current"
          :enabled="enabled"
          :focused="true"
          :goal="goal"
          :me="me"
          :session="session"
          :post="post"
          @save:post="emit('save:post', $event)"
          @add:attachment="emit('add:attachment', $event)"
          @create:attachment="emit('create:attachment', $event)"
          @delete:attachment="emit('delete:attachment', $event)"
          @set:current="current = $event"
        />
      </div>

      <div class="p-4 md:overflow-auto md:p-8 md:pt-28 md:h-screen">
        <h2 class="mb-4 text-2xl">Voorgaande feedback</h2>
        <h3 class="mb-8 font-semibold">
          {{ goal.student.name }} – {{ goal.competency.title }}
        </h3>

        <div v-if="isDone && data.competencyPosts.length">
          <session-goal-focus-post
            v-for="post in data.competencyPosts"
            :key="post.id"
            :post="post"
          />
        </div>

        <p v-else>Er is nog geen voorgaande feedback.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref, PropType } from 'vue'
  import { useQuery } from 'villus'
  import { useI18n } from 'vue-i18n'

  import { XMarkIcon } from '@heroicons/vue/24/solid'
  import IconButton from './IconButton.vue'
  import SessionGoalCard from './SessionGoalCard.vue'
  import SessionGoalFocusPost from './SessionGoalFocusPost.vue'
  import TopHeader from './TopHeader.vue'

  import query from '../graphql/CompetencyPosts.graphql'

  const { locale } = useI18n()

  const props = defineProps({
    enabled: {
      type: Boolean,
      required: true,
    },

    goal: {
      type: Object as PropType<Goal>,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const emit = defineEmits([
    'save:post',
    'set:focused',
    'add:attachment',
    'create:attachment',
    'delete:attachment',
  ])
  const current = ref(false)

  const variables = {
    competencyId: props.goal.competency.id,
    studentId: props.goal.student.id,
  }
  const { data, isDone } = useQuery({ query, variables })

  const post = computed(() => {
    return props.session.posts.find((p) => p.goal?.id === props.goal?.id)
  })
</script>
