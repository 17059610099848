<template>
  <session-goal-card
    :enabled="session.enabled && isDone"
    :goal="goal"
    :me="me"
    :post="post"
    :session="session"
    @add:attachment="emit('add:attachment', $event)"
    @create:attachment="emit('create:attachment', $event)"
    @delete:attachment="emit('delete:attachment', $event)"
    @save:post="emit('save:post', $event)"
    @set:focused="focused = true"
  />
</template>

<script lang="ts" setup>
  import { ref, PropType } from 'vue'
  import SessionGoalCard from './SessionGoalCard.vue'

  defineProps({
    goal: {
      type: Object as PropType<Goal>,
    },

    isDone: {
      type: Boolean,
      required: true,
    },

    me: {
      type: Object as PropType<User>,
      required: true,
    },

    post: {
      type: Object as PropType<Post>,
    },

    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  })

  const emit = defineEmits([
    'add:attachment',
    'create:attachment',
    'delete:attachment',
    'save:post',
  ])

  const focused = ref(false)
</script>
